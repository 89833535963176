<template>
  <div>
    <div class="d-flex flex-row justify-content-between py-3">
      <router-link
        :to="{
          name: $route.query.parent_url
        }"
        v-slot="{ href, route, navigate }"
        custom
      >
        <a :href="href" @click="navigate" class="mr-2"
          ><i class="fa fa-chevron-left mr-1"></i>Go back to case list</a
        >
      </router-link>
    </div>
    <div class="d-flex justify-content-start">
      <h1>Case : {{ this.case.case_number }}</h1>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="closeCase"
        v-if="$route.query.parent_url == 'open-cases'"
        ><i class="fas fa-window-close mr-1"></i>Close Case</b-button
      >
      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="openCase"
        v-if="
          $route.query.parent_url != 'close-cases' &&
          $route.query.parent_url != 'open-cases'
        "
        ><i class="fa fa-folder-open mr-1"></i>Open Case</b-button
      >
      <!--      <b-button variant="primary" size="sm" @click="unlockCase" disabled -->
      <!--        ><i class="fas fa-unlock mr-1"></i>Unlocked</b-button-->
      <!--      >-->
    </div>
    <div class="d-flex flex-row">
      <b-card-group class="w-100">
        <b-card class="bg-surface-2 mt-3 w-100">
          <b-card-text>Case Number : {{ this.case.case_number }} </b-card-text>
          <b-card-text
            >Date : {{ this.case.created | dateTime12Hour }}
          </b-card-text>
          <b-card-text
            >Last accessed by : {{ this.case.last_accessed_by }}
          </b-card-text>
          <b-card-text
            >Last accessed time :
            {{ this.case.last_accessed | dateTime12Hour }}
          </b-card-text>
        </b-card>
        <b-card class="bg-surface-2 mt-3 w-100">
          <b-card-text
            >Severity : {{ this.case.priority | humanizeText2 }}
          </b-card-text>
          <b-card-text
            >Status : {{ this.case.status | humanizeText2 }}
          </b-card-text>
          <b-card-text>Reporter : {{ this.case.reporter_id }} </b-card-text>
          <b-card-text v-if="$route.query.parent_url == 'open-cases'"
            >Assigned To :
            <multiselect
              v-show="isSelect"
              placeholder="Please select"
              label="name"
              track-by="id"
              v-model="formUpdateCase.assignedTo"
              :options="userList"
              :allow-empty="false"
              @select="onSelect"
            ></multiselect>
            <b-link
              @click="navigateHandler"
              v-show="!isSelect"
              v-click-outside="hideSelect"
              ><u>{{
                this.case.assignee_id | humanizeText | dashForEmptyText
              }}</u></b-link
            >
          </b-card-text>
          <b-card-text v-if="$route.query.parent_url == 'close-cases'"
            >Assigned To :
            <multiselect
              v-if="isSelect"
              placeholder="Please select"
              label="name"
              track-by="id"
              v-model="formUpdateCase.assignedTo"
              :options="userList"
              :allow-empty="false"
              @select="onSelect"
            ></multiselect>
            <!--            <b-link @click="navigateHandler" v-if="!isSelect"-->
            <!--            ><u>-->
            {{ this.case.assignee_id | humanizeText | dashForEmptyText }}
            <!--            </u></b-link-->
            <!--            >-->
          </b-card-text>
          <b-card-text
            ><b-button
              v-if="!hideAssignButton"
              variant="primary"
              size="sm"
              @click="onSubmitUpdateAssign(getCurrentUser)"
              ><i class="fa fa-user-circle mr-1"></i>Assign to myself</b-button
            >
          </b-card-text>
        </b-card>
        <b-card class="bg-surface-2 mt-3 w-100">
          <div class="d-flex justify-content-end">
            <b-dropdown
              size="sm"
              variant="primary"
              title="Case Actions"
              v-b-tooltip.hover
              :disabled="busy"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <div v-if="$route.query.parent_url == 'open-cases'">
                <b-dropdown-item @click="updateCaseHandler"
                  >Edit
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCaseHandler"
                  >Delete
                </b-dropdown-item>
                <b-dropdown-item @click="exportPDF"
                  >Export to PDF
                </b-dropdown-item>
              </div>
              <div v-if="$route.query.parent_url == 'close-cases'">
                <b-dropdown-item @click="openCase"
                  >Reopen Case
                </b-dropdown-item>
                <b-dropdown-item @click="exportPDF"
                  >Export to PDF
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
          <div class="d-flex justify-content-start">
            <b-card-text>None </b-card-text>
          </div>
          <div class="d-flex justify-content-start">
            <b-card-text
              >Description : {{ this.case.description }}
            </b-card-text>
          </div>
        </b-card>
      </b-card-group>
    </div>

    <div class="d-flex justify-content-start pt-4">
      <h1>Action list</h1>
    </div>
    <div
      class="d-flex flex-row justify-content-end"
      v-if="$route.query.parent_url != 'close-cases'"
    >
      <b-button variant="primary" class="mr-1" size="sm" @click="addHandler"
        ><i class="fa fa-plus mr-1"></i>Add Action</b-button
      >
    </div>
    <div class="d-flex justify-content-between pt-1">
      <!-- Main table element -->
      <b-table
        :busy="busy"
        :items="actionList"
        :fields="tableFields"
        :filter="searchTerms"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-desc.sync="sortDesc"
        :sort-by.sync="sortBy"
        small
        striped
        show-empty
        sticky-header
        stacked="md"
        class="tableBorder"
      >
        <template #table-busy>
          <div class="text-center text-white my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>
        <template #cell(created)="row">
          {{ row.item.created | dateTime12Hour }}
        </template>
        <template #cell(description)="row">
          <div>{{ truncateText(row.item.description, 100) }}</div>
        </template>
        <template #cell(attachment)="row">
          <i
            v-if="row.item.attachment.length > 0"
            class="fas fa-paperclip mr-1"
            @click="downloadFileHandler(row.item.attachment)"
          ></i>
          <span v-if="row.item.attachment.length == 0">-</span>
        </template>
        <template #cell(action)="row">
          {{ row.item.action | humanizeText2 }}
        </template>
        <template
          v-if="$route.query.parent_url === 'open-cases'"
          #cell(do)="row"
        >
          <b-button
            class="mr-1"
            size="sm"
            title="Update"
            variant="secondary"
            v-b-tooltip.hover
            @click="updateCaseActionHandler(row.item)"
          >
            <i class="fa fa-edit"></i>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            v-b-tooltip.hover
            title="Delete"
            @click="deleteCaseActionHandler(row.item)"
          >
            <i class="fa fa-trash-alt"></i>
          </b-button>
        </template>
        <template
          v-else-if="$route.query.parent_url === 'close-cases'"
          #head(do)
        >
          <th></th>
        </template>
        <!--        <template #cell(do)="row">-->
        <!--          <b-button-->
        <!--            class="mr-1"-->
        <!--            size="sm"-->
        <!--            title="Update"-->
        <!--            variant="secondary"-->
        <!--            v-b-tooltip.hover-->
        <!--            @click="updateCaseActionHandler(row.item)"-->
        <!--          >-->
        <!--            <i class="fa fa-edit"></i>-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            variant="danger"-->
        <!--            size="sm"-->
        <!--            v-b-tooltip.hover-->
        <!--            title="Delete"-->
        <!--            @click="deleteCaseActionHandler(row.item)"-->
        <!--          >-->
        <!--            <i class="fa fa-trash-alt"></i>-->
        <!--          </b-button>-->
        <!--        </template>-->
      </b-table>
    </div>
    <!-- pagination -->
    <div class="d-flex justify-content-between" v-if="!busy && !searchTerms">
      <div class="d-flex">
        <b-form-select
          id="per-page-select"
          size="sm"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
        Per Page
      </div>

      <div class="d-flex">
        <b-pagination
          v-if="perPage !== 'all'"
          class="d-flex"
          size="sm"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>

    <!--Update Case Modal -->
    <b-modal
      id="formUpdateCase"
      ref="formUpdateCase"
      centered
      hide-footer
      :title="titleUpdateCase()"
      :no-close-on-backdrop="true"
      :hide-header-close="false"
    >
      <b-form @submit="onSubmitUpdateCaseForm">
        <b-form-group
          id="select-group-assigned-to"
          label="Assigned to:"
          label-for="select-assigned-to"
        >
          <multiselect
            v-model="formUpdateCase.assignedTo"
            :options="userList"
            placeholder="Please select"
            label="name"
            track-by="id"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>

        <b-form-group
          id="select-group-priority"
          label="Severity:"
          label-for="select-priority"
        >
          <multiselect
            v-model="formUpdateCase.priority"
            :options="priorityList"
            placeholder="Please select"
            label="name"
            track-by="value"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>

        <b-form-group
          id="select-group-result"
          label="Result:"
          label-for="select-result"
        >
          <multiselect
            v-model="formUpdateCase.result"
            :options="resultList"
            placeholder="Please select"
            label="name"
            track-by="value"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>
        <b-form-group
          id="select-group-status"
          label="Status:"
          label-for="select-status"
        >
          <multiselect
            v-model="formUpdateCase.status"
            :options="statusList"
            placeholder="Please select"
            label="name"
            track-by="value"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>

        <b-form-group id="input-group-visitor-type" label-for="input-type">
          <template v-slot:label>
            Description: <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="input-type"
            type="text"
            rows="5"
            v-model="$v.formUpdateCase.description.$model"
            placeholder="Please input description....."
            aria-describedby="formUpdateCase.description_feedback"
            :state="validateFormUpdateCase('description')"
          ></b-form-textarea>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="formUpdateCase.description_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-danger" v-if="formUpdateCaseErrorMessage">
          {{ formUpdateCaseErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <!--Add Action Modal -->
    <b-modal
      id="formAddCaseAction"
      ref="formAddCaseAction"
      centered
      hide-footer
      :title="'Add Case Action'"
      :no-close-on-backdrop="true"
      :hide-header-close="false"
    >
      <b-form @submit="onSubmitAddForm">
        <b-form-group
          id="select-group-actions"
          label="Actions:"
          label-for="select-actions"
        >
          <multiselect
            v-model="formAdd.action"
            :options="actionsList"
            placeholder="Please select"
            label="name"
            track-by="value"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>

        <b-form-group
          id="input-group-description"
          label-for="input-type-description"
        >
          <template v-slot:label>
            Description: <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="input-type"
            type="text"
            rows="5"
            v-model="$v.formAdd.description.$model"
            placeholder="Please input description....."
            aria-describedby="formAdd.description_feedback"
            :state="validateFormAdd('description')"
          ></b-form-textarea>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="formAdd.description_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-file"
          label="Attachment:"
          label-for="input-type-file"
        >
          <!-- :state="Boolean(formAdd.attachment)" -->
          <b-form-file
            v-model="formAdd.attachment"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div class="mt-3">
            Selected file:
            {{ formAdd.attachment ? formAdd.attachment.name : "No file" }}
            <i
              class="far fa-window-close ml-3"
              v-if="formAdd.attachment != null"
              @click="deleteFileHandler"
            ></i>
          </div>
        </b-form-group>

        <div class="text-danger" v-if="formAddErrorMessage">
          {{ formAddErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <!--Update Action Modal -->
    <b-modal
      id="formUpdateCaseAction"
      ref="formUpdateCaseAction"
      centered
      hide-footer
      :title="'Edit Case Action'"
      :no-close-on-backdrop="true"
      :hide-header-close="false"
    >
      <b-form @submit="onSubmitUpdateForm">
        <b-form-group
          id="select-group-actions"
          label="Actions:"
          label-for="select-actions"
        >
          <multiselect
            v-model="formUpdate.action"
            :options="actionsList"
            placeholder="Please select"
            label="name"
            track-by="value"
            :allow-empty="false"
          ></multiselect>
        </b-form-group>

        <b-form-group
          id="input-group-description"
          label-for="input-type-description"
        >
          <template v-slot:label>
            Description: <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="input-type"
            type="text"
            rows="5"
            v-model="$v.formUpdate.description.$model"
            placeholder="Please input description....."
            aria-describedby="formUpdate.description_feedback"
            :state="validateFormUpdate('description')"
          ></b-form-textarea>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="formUpdate.description_feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-file"
          label="Attachment:"
          label-for="input-type-file"
        >
          <!-- :state="Boolean(formUpdate.attachment)" -->
          <b-form-file
            v-model="formUpdate.attachment"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div class="mt-3">
            Selected file:
            {{ formUpdate.attachment ? formUpdate.attachment.name : "No file" }}
            <i
              class="far fa-window-close ml-3"
              v-if="formUpdate.attachment != null"
              @click="deleteFileHandler"
            ></i>
          </div>
        </b-form-group>

        <div class="text-danger" v-if="formUpdateErrorMessage">
          {{ formUpdateErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import Multiselect from "vue-multiselect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
export default {
  components: {
    Multiselect
  },
  // do not forget this section
  directives: {
    ClickOutside
  },
  data: function () {
    return {
      case: {},
      scMode: null,
      busy: false,
      sortBy: "created",
      sortDesc: true,
      totalRows: 0,
      perPage: 10,
      currentPage: 0,
      pageOptions: [5, 10, 15],
      userList: [],
      actionList: [],
      roleList: null,
      searchTerms: "",
      filterOn: [],
      name_case_action: null,
      isSelect: false,
      formAddErrorMessage: null,
      formAdd: {
        action: { value: "INVESTIGATING", name: "Investigating" },
        description: null,
        attachment: null
      },
      formUpdateErrorMessage: null,
      formUpdate: {
        case_id: null,
        case_action_id: null,
        action: null,
        description: null,
        case_attachment_id: null,
        attachment: null
      },
      formUpdateCaseErrorMessage: null,
      formUpdateCase: {
        case_number: null,
        id: null,
        assignedTo: null,
        priority: null,
        result: null,
        alert: null,
        status: null,
        description: null
      },
      eventType: [
        { value: "LPR", name: "SC_LPR" },
        { value: "VMS", name: "SC_VMS" },
        { value: "SC", name: "SC_DASHBOARD" }
      ],
      actionsList: [
        { value: "INVESTIGATING", name: "Investigating" },
        { value: "ACTION_TAKEN", name: "Action Taken" },
        { value: "FALSE_ALARM", name: "False Alarm" }
      ],
      priorityList: [
        { value: "HIGH", name: "High" },
        { value: "MEDIUM", name: "Medium" },
        { value: "LOW", name: "Low" }
      ],
      resultList: [
        { value: "PENDING_CONFIRMATION", name: "Pending Confirmation" },
        { value: "VALID_EVENT", name: "Valid Event" },
        { value: "INVALID_EVENT", name: "Invalid Event" }
      ],
      alertList: [
        { value: "higest", name: "Highest" },
        { value: "high", name: "High" },
        { value: "normal", name: "Normal" },
        { value: "low", name: "Low" },
        { value: "lowest", name: "Lowest" }
      ],
      statusList: [
        { value: "OPEN", name: "Open" },
        { value: "CLOSED", name: "Closed" }
      ],
      tableFields: [
        {
          key: "index",
          label: "No.",
          thStyle: { width: "3%" }
        },
        {
          key: "created",
          label: "Date time",
          sortable: true
        },
        {
          key: "user_id",
          label: "User",
          sortable: true
        },
        {
          key: "action",
          label: "Action",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "attachment",
          label: "Attachment",
          sortable: true
        },
        { key: "do", label: "Actions" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getCaseActions() {
      return this.$store.getters["case/getCaseActions"](null);
    },
    hideAssignButton() {
      if (this.case && this.getCurrentUser) {
        let name =
          this.getCurrentUser.name != null
            ? this.getCurrentUser.name
            : this.getCurrentUser.username;
        let currentUserName = name.toLowerCase();
        let assigneeId = this.case.assignee_id
          ? this.case.assignee_id.toLowerCase()
          : null;
        return assigneeId && currentUserName === assigneeId;
      }
      return false;
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 10;
      }
      this.getData();
    },
    getCaseActions: function (n, o) {
      this.actionList = n;
      for (let key in this.userList) {
        console.log("this.user", this.userList);
        this.name_case_action = this.userList[key].name;
        for (let key2 in this.actionList) {
          if (this.actionList[key2].user_id == this.userList[key].id) {
            console.log("action list", this.actionList[key2]);
            this.actionList[key2].user_id = this.name_case_action;
          }
        }
      }
    }
  },
  validations: {
    formAdd: {
      description: { required }
    },
    formUpdate: {
      description: { required }
    },
    formUpdateCase: {
      description: { required }
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 10;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.scMode = await this.fetchLicenseInfo();
      console.log("this.scMode", this.scMode);
      await this.fetchRolesList();
      console.log("this.roleList", this.roleList);
      await this.fetchUserList();
      this.$store.dispatch("case/resetCase");
      await this.fetchCaseByCaseID();
      this.$store.dispatch("case/resetCaseAction");
      await this.fetchCaseActionByCaseID();
      await this.fetchPaginationCaseActionByCaseID();
    },
    async fetchLicenseInfo() {
      let API_URL = this.getAPIServerURL + "/license/info/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          validUrl = key;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        for (let key26 in this.eventType) {
          if (result[validUrl].sc_mode == this.eventType[key26].name) {
            return this.eventType[key26].value;
          }
        }
      }

      return [];
    },
    async fetchRolesList() {
      let API_URL = this.getAPIServerURL + "/roles/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;

      if (result != undefined && Array.isArray(result)) {
        for (let key7 in result) {
          if (this.scMode == "VMS" && result[key7].name == "VMS Operator") {
            this.roleList = result[key7];
          }
        }
      }

      return [];
    },
    async fetchUserList() {
      let API_URL = this.getAPIServerURL + "/users/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        for (let key3 in result) {
          validUrl = key3;
          if (result[validUrl] != undefined) {
            if (
              this.scMode == "VMS" &&
              !result[validUrl].is_superuser &&
              (result[validUrl].is_admin ||
                (result[validUrl].role_id == this.roleList.id &&
                  result[validUrl].is_active == true))
            ) {
              if (result[validUrl].name == null) {
                result[validUrl].name = result[validUrl].username;
              }

              result[validUrl].name = _.startCase(result[validUrl].name);
              this.userList.push(result[validUrl]);
            }

            if (
              this.scMode != "VMS" &&
              !result[validUrl].is_superuser &&
              result[validUrl].is_active == true
            ) {
              if (result[validUrl].name == null) {
                result[validUrl].name = result[validUrl].username;
              }

              result[validUrl].name = _.startCase(result[validUrl].name);
              this.userList.push(result[validUrl]);
            }
          }
        }
      }

      return [];
    },
    async fetchCaseByCaseID() {
      let query = {
        case_id: this.$route.params.id
      };

      let API_URL = this.getAPIServerURL + "/api/caseservice/case/?";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              validUrl = key;
              break outer_loop;
            }
          }
          if (result[validUrl] != undefined) {
            for (let key6 in this.userList) {
              if (result[validUrl].assignee_id == this.userList[key6].id) {
                let name = null;
                if (this.userList[key6].name != null) {
                  name = this.userList[key6].name;
                } else {
                  name = this.userList[key6].username;
                }
                result[validUrl].assignee_id = name;
              }

              if (result[validUrl].reporter_id == this.userList[key6].id) {
                let name = null;
                if (this.userList[key6].name != null) {
                  name = this.userList[key6].name;
                } else {
                  name = this.userList[key6].username;
                }
                result[validUrl].reporter_id = name;
              }

              if (result[validUrl].last_accessed_by == this.userList[key6].id) {
                let name = null;
                if (this.userList[key6].name != null) {
                  name = this.userList[key6].name;
                } else {
                  name = this.userList[key6].username;
                }
                result[validUrl].last_accessed_by = name;
              }
            }
            this.case = result[validUrl];
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchCaseActionByCaseID() {
      this.busy = true;
      let query = {};
      //if (this.perPage === "all") {
      query = null;
      /* } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      } */
      let API_URL =
        this.getAPIServerURL +
        "/api/caseservice/case/" +
        this.$route.params.id +
        "/actions/?";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query)
        .then(async (data) => {
          let result = data.result;
          let attachment = "";
          if (result !== undefined) {
            outer_loop: for (let key4 in result) {
              attachment = key4;
              break outer_loop;
            }
          }
          if (result[attachment] != undefined) {
            for (let key20 in result[attachment]) {
              result[attachment][key20]["attachment"] =
                await this.fetchCaseAttachmentByCaseActionID(
                  result[attachment][key20].id
                );

              this.$store.dispatch(
                "case/addCaseAction",
                result[attachment][key20]
              );
            }

            this.busy = false;
            this.totalRows = result[attachment].length;
          }
        })
        .catch((err) => {
          this.busy = false;
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchPaginationCaseActionByCaseID() {
      let query = {};

      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }
      let API_URL =
        this.getAPIServerURL +
        "/api/caseservice/case/" +
        this.$route.params.id +
        "/actions/?";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query)
        .then(async (data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key4 in result) {
              validUrl = key4;
              break outer_loop;
            }
          }
          if (result[validUrl] != undefined) {
            /* if (result[validUrl]["total"] !== undefined) {
              this.totalRows = parseInt(result[validUrl]["total"]);
              
            } */

            if (result[validUrl]["page"] !== undefined) {
              this.currentPage = parseInt(result[validUrl]["page"]);
            }

            if (result[validUrl]["size"] !== undefined) {
              this.perPage = parseInt(result[validUrl]["size"]);
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchCaseAttachmentByCaseActionID(id) {
      let API_URL =
        this.getAPIServerURL +
        "/api/caseservice/case-action/" +
        id +
        "/attachments/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key21 in result) {
          validUrl = key21;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }

      return [];
    },

    downloadFileHandler(item) {
      console.log("item", item);
      for (let key22 in item) {
        let url = item[key22].attachment;
        const client = this.$root.getAjaxFetchClient();
        client.responseType = "blob";
        client.getRequest(url).then((result) => {
          result;
          let fileName = item[key22].attachment_filename;
          const link = document.createElement("a");
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(result);
          // some browser needs the anchor to be in the doc
          link.download = fileName;
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        });
      }
    },
    deleteFileHandler() {
      this.$bvModal
        .msgBoxConfirm("Are you sure to remove attachment?", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "Cancel",
          centered: true
        })
        .then((value) => {
          if (value) {
            console.log("value", value);
            this.formAdd.attachment = null;
            this.formUpdate.attachment = null;
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    //handle pagination
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    //assign user
    navigateHandler() {
      if (!this.select) {
        this.isSelect = true;
        for (let index1 in this.userList) {
          if (this.case.assignee_id == this.userList[index1].name) {
            this.formUpdateCase.assignedTo = this.userList[index1].id;
          }

          if (this.case.assignee_id == this.userList[index1].username) {
            this.formUpdateCase.assignedTo = this.userList[index1].id;
          }
        }
      }
    },
    onSelect(item) {
      console.log("item", item);
      let messageText = null;
      if (item.name != null) {
        messageText =
          "Are you sure you want to change Assigned To from " +
          this.case.assignee_id +
          " to " +
          item.name +
          " ?";
      } else {
        messageText =
          "Are you sure you want to change Assigned To from " +
          this.case.assignee_id +
          " to " +
          item.username +
          " ?";
      }
      this.$bvModal
        .msgBoxConfirm(messageText, {
          centered: true
        })
        .then((value) => {
          if (value) {
            this.onSubmitUpdateAssign(item);
          } else {
            this.isSelect = false;
            messageText = null;
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.isSelect = false;
          messageText = null;
        });
    },
    onSubmitUpdateAssign(item) {
      let changeAssign = {};
      changeAssign["assignee_id"] = item.id;
      changeAssign["previous_data"] = {};

      console.log("changeAssign", changeAssign);
      console.log("this.case", this.case);

      let API_URL =
        this.getAPIServerURL +
        "/api/caseservice/case/change-assignee/?case_id=" +
        this.case.id;
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, changeAssign)
        .then((data) => {
          if (data.detail != undefined) {
            console.log("data_error_update", data.detail);
            this.isSelect = false;
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key8 in result) {
                if (result != undefined) {
                  validUrl = key8;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              // update visitor to store
              let assignedTo = null;
              if (item.name != null) {
                assignedTo = item.name;
              } else {
                assignedTo = item.username;
              }
              this.$store.dispatch("case/updateCase", {
                id: this.case.id,
                data: {
                  case_number: this.case.case_number,
                  assignedTo: assignedTo,
                  priority: this.case.priority,
                  result: this.case.result,
                  alert: this.case.alert,
                  status: this.case.status,
                  description: this.case.description
                }
              });

              this.case.assignee_id = assignedTo;

              this.isSelect = false;

              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Assigned To has been changed successfully.",
                message_type: "success"
              });
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    // option do open or close and lock or unlock case
    closeCase() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to close this Case ? ", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.onSubmitCloseCase();
          } else {
            //
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitCloseCase() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/close/?case_id=" +
        this.case.id +
        "&case_number=" +
        this.case.case_number;
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL)
        .then((data) => {
          if (data.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key13 in result) {
                if (result != undefined) {
                  validUrl = key13;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              //this.$store.dispatch("case/updateCase", this.case.id);
              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Case status has been update successfully",
                message_type: "success"
              });
              //location.reload();
              this.$router.push({
                name: "close-cases"
              });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    openCase() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to open this Case ? ", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.onSubmitOpenCase();
          } else {
            //
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitOpenCase() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/open/?case_id=" +
        this.case.id +
        "&case_number=" +
        this.case.case_number;
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL)
        .then((data) => {
          if (data.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key13 in result) {
                if (result != undefined) {
                  validUrl = key13;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              //this.$store.dispatch("case/updateCase", this.case.id);
              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Case status has been update successfully",
                message_type: "success"
              });
              //location.reload();
              this.$router.push({
                name: "open-cases"
              });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    unlockCase() {
      //
    },

    // option do change edit, delete, export pdf
    titleUpdateCase() {
      let title = "Edit Case - " + this.case.case_number;
      return title;
    },
    updateCaseHandler() {
      let $this = this;
      let nonexist1 = true;
      let nonexist2 = true;
      let nonexist3 = true;
      let nonexist4 = true;
      let nonexist5 = true;

      $this.formUpdateCase.id = this.case.id;
      $this.formUpdateCase.case_number = this.case.case_number;

      for (let index1 in $this.userList) {
        if ($this.userList[index1].name == this.case.assignee_id) {
          nonexist1 = false;
          $this.formUpdateCase.assignedTo = {
            value: $this.userList[index1].id,
            name: $this.userList[index1].name
          };
          $this.formUpdateCase.reporter_id = {
            value: $this.userList[index1].id,
            name: $this.userList[index1].name
          };
        }
        if ($this.userList[index1].username == this.case.assignee_id) {
          nonexist1 = false;
          $this.formUpdateCase.assignedTo = {
            value: $this.userList[index1].id,
            name: $this.userList[index1].username
          };
          $this.formUpdateCase.reporter_id = {
            value: $this.userList[index1].id,
            name: $this.userList[index1].username
          };
        }
      }

      if (nonexist1) {
        $this.formUpdateCase.assignedTo = {
          value: 0,
          name: "-"
        };
        $this.formUpdateCase.reporter_id = {
          value: 0,
          name: "-"
        };
      }

      for (let index2 in $this.priorityList) {
        if ($this.priorityList[index2].value == this.case.priority) {
          nonexist2 = false;
          $this.formUpdateCase.priority = {
            value: $this.priorityList[index2].value,
            name: $this.priorityList[index2].name
          };
        }
      }

      if (nonexist2) {
        $this.formUpdateCase.priority = {
          value: "MEDIUM",
          name: "Medium"
        };
      }

      for (let index3 in $this.resultList) {
        if ($this.resultList[index3].value == this.case.result) {
          nonexist3 = false;
          $this.formUpdateCase.result = {
            value: $this.resultList[index3].value,
            name: $this.resultList[index3].name
          };
        }
      }

      if (nonexist3) {
        $this.formUpdateCase.result = {
          value: "pending_confirmation",
          name: "Pending Confirmation"
        };
      }

      for (let index4 in $this.alertList) {
        if ($this.alertList[index4].value == this.case.alert) {
          nonexist4 = false;
          $this.formUpdateCase.alert = {
            value: $this.alertList[index4].value,
            name: $this.alertList[index4].name
          };
        }
      }

      if (nonexist4) {
        $this.formUpdateCase.alert = {
          value: "normal",
          name: "Normal"
        };
      }

      for (let index5 in $this.statusList) {
        if ($this.statusList[index5].value == this.case.status) {
          nonexist5 = false;
          $this.formUpdateCase.status = {
            value: $this.statusList[index5].value,
            name: $this.statusList[index5].name
          };
        }
      }

      if (nonexist5) {
        $this.formUpdateCase.status = {
          value: "OPEN",
          name: "Open"
        };
      }

      $this.formUpdateCase.description = this.case.description;
      this.$bvModal.show("formUpdateCase");
    },
    validateFormUpdateCase(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formUpdateCase[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationUpdateCaseForm() {
      let $this = this;
      $this.$v.formUpdateCase.$touch();
      return $this.$v.formUpdateCase.$anyError;
    },
    onSubmitUpdateCaseForm: function (e) {
      e.preventDefault();
      let $this = this;
      let anyError = $this.onSubmitValidationUpdateCaseForm();
      if (!anyError) {
        let query = {
          assignee_id: $this.formUpdateCase.assignedTo.id
        };
        if (query["assignee_id"] == undefined) {
          query["assignee_id"] = $this.formUpdateCase.assignedTo.value;
        }
        let API_URL =
          $this.getAPIServerURL +
          "/api/caseservice/case/change-assignee/?case_id=" +
          $this.formUpdateCase.id;
        const client = $this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, query)
          .then(async (data) => {
            if (data.detail != undefined) {
              console.log("data_error_update", data.detail);
              this.formUpdateErrorMessage = data.detail;
            } else {
              let editCase = {};
              editCase["event_type"] = $this.scMode;
              editCase["assignee_id"] = $this.formUpdateCase.assignedTo.value;
              editCase["priority"] = $this.formUpdateCase.priority.value;
              editCase["result"] = $this.formUpdateCase.result.value;
              editCase["alert"] = $this.formUpdateCase.alert.value;
              editCase["status"] = $this.formUpdateCase.status.value;
              editCase["description"] = $this.formUpdateCase.description;

              let API_URL =
                this.getAPIServerURL +
                "/api/caseservice/case/?case_id=" +
                $this.formUpdateCase.id;
              const client = $this.$root.getAjaxFetchClient();
              client.putRequest(API_URL, editCase).then(async (data) => {
                if (data.detail != undefined) {
                  console.log("data_error_update", data.detail);
                  this.formUpdateCaseErrorMessage = data.detail;
                } else {
                  let result = data.result;
                  let validUrl = "";

                  if (result != undefined) {
                    outer_loop: for (let key10 in result) {
                      if (result != undefined) {
                        validUrl = key10;
                        break outer_loop;
                      }
                    }
                  }
                  if (result[validUrl] == null) {
                    // update case to store
                    if (editCase.status == "CLOSED") {
                      await this.onSubmitCloseCase($this.formUpdate);

                      this.$store.dispatch("case/updateCase", {
                        id: $this.formUpdateCase.id,
                        data: {
                          case_number: $this.formUpdateCase.case_number,
                          assignedTo: $this.formUpdateCase.assignedTo.id,
                          priority: $this.formUpdateCase.priority.value,
                          result: $this.formUpdateCase.result.value,
                          alert: $this.formUpdateCase.alert.value,
                          status: $this.formUpdateCase.status.value,
                          description: $this.formUpdateCase.description
                        }
                      });

                      this.$bvModal.hide("formUpdateCase");
                      this.$bvModal
                        .msgBoxOk("Case has been updated successfully", {
                          centered: true
                        })
                        .then((value) => {
                          if (value) {
                            this.$router.push({
                              name: "close-cases"
                            });
                          }
                        });
                    } else {
                      await this.onSubmitOpenCase($this.formUpdate);
                      this.$store.dispatch("case/updateCase", {
                        id: $this.formUpdateCase.id,
                        data: {
                          case_number: $this.formUpdateCase.case_number,
                          assignedTo: $this.formUpdateCase.assignedTo.id,
                          priority: $this.formUpdateCase.priority.value,
                          result: $this.formUpdateCase.result.value,
                          alert: $this.formUpdateCase.alert.value,
                          status: $this.formUpdateCase.status.value,
                          description: $this.formUpdateCase.description
                        }
                      });
                      this.$bvModal.hide("formUpdateCase");
                      this.$bvModal
                        .msgBoxOk("Case has been updated successfully", {
                          centered: true
                        })
                        .then((value) => {
                          if (value) {
                            location.reload();
                          }
                        });
                    }
                  }
                }
              });
            }
          })
          .catch((err) => {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        // this.$bvModal.msgBoxOk("Form not completed!", {
        //   centered: true
        // });
        return false;
      }
    },
    deleteCaseHandler() {
      this.$bvModal
        .msgBoxConfirm("Confirm deleting case " + this.case.case_number + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.onSubmitDeleteCaseForm();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitDeleteCaseForm() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/?case_id=" +
        this.case.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then((data) => {
          if (data.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key12 in result) {
                if (result != undefined) {
                  validUrl = key12;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              this.$store.dispatch("case/deleteCase", this.case.id);
              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Case has been delete successfully",
                message_type: "success"
              });

              this.$router.push({ name: this.$route.query.parent_url });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    async exportPDF() {
      if (this.actionList.length > 0) {
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating PDF report with " +
            this.actionList.length +
            " cases",
          message_type: "info"
        });
      }

      let result = this.actionList;
      let bodyData1 = [];
      let i = 0;
      let startDate = null;
      let endDate = null;

      for (let key28 in result) {
        let data1 = [];
        i += 1;
        data1.push(i + ".");
        if (result[key28] == null) {
          data1.push("-");
        } else {
          if (result[key28].created != undefined) {
            data1.push(
              moment(result[key28].created).format("DD/MM/YYYY HH:mm A")
            );
          } else {
            data1.push("-");
          }
          data1.push(_.startCase(result[key28].user_id));
          data1.push(_.startCase(_.toLower(result[key28].action)));
          data1.push(result[key28].description);

          if (i == result.length) {
            startDate = moment(result[key28].created).format("DD/MM/YYYY");
          }

          if (parseInt(key28) + 1 == 1) {
            endDate = moment(result[key28].created).format("DD/MM/YYYY");
          }
        }
        bodyData1.push(data1);
      }

      let bodyData2 = [];
      let data2 = [];
      let data3 = [];
      let data4 = [];
      let data5 = [];

      data2.push("Case Number: " + this.case.case_number);
      data2.push("Severity: " + _.startCase(_.toLower(this.case.priority)));
      //data2.push("None");
      data3.push(
        "Date: " + moment(this.case.created).format("DD/MM/YYYY HH:mm A")
      );
      data3.push("Status: " + _.startCase(_.toLower(this.case.status)));
      //data3.push("Description: " + this.case.description);
      data4.push("Last accessed by: " + this.case.last_accessed_by);
      data4.push("Reporter: " + this.case.reporter_id);
      data5.push(
        "Last accessed time: " +
          moment(this.case.last_accessed).format("DD/MM/YYYY HH:mm A") +
          " "
      );
      data5.push("Assigned To: " + this.case.assignee_id);
      bodyData2.push(data2, data3, data4, data5);

      let doc = new jsPDF();
      doc
        .text("Case ID: " + this.case.case_number, 107, 20, "center")
        .setFontSize(5);
      doc
        .text("Generate On: " + moment().format("LLL"), 170, 5, "left")
        .setFontSize(9);
      doc.text("None", 14, 70);
      doc.text("Description:", 14, 75);
      doc.text(this.case.description, 14, 80);
      doc.text("Action List:", 14, 90).setFontSize(9);
      doc.text(
        "Initial Action On: " +
          startDate +
          "   Latest Action On: " +
          endDate +
          "   Total Actions: " +
          result.length,
        14,
        95
      );

      doc.autoTable({
        html: "#my-table"
      });
      doc.autoTable({
        theme: "plain",
        cellWidth: "auto",
        body: bodyData2,
        tableLineWidth: 1,
        startY: 30
      });
      doc.autoTable({
        head: [["No.", "Date Time", "User", "Action", "Description"]],
        body: bodyData1,
        theme: "grid",
        headStyles: { fillColor: [238, 238, 238], textColor: [0, 0, 0] },
        lineColor: [0, 0, 0],
        startY: 100
      });

      doc.save("Case Report of " + this.case.case_number);
      await this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "PDF report with " +
          result.length +
          " cases has been downloaded successfully",
        message_type: "info"
      });
    },
    // add action
    addHandler() {
      this.formAdd.action = { value: "INVESTIGATING", name: "Investigating" };
      this.formAdd.description = null;
      this.formAdd.attachment = null;

      this.$v.$reset();
      this.$bvModal.show("formAddCaseAction");
    },
    validateFormAdd(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formAdd[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationAddForm() {
      let $this = this;
      $this.$v.formAdd.$touch();
      return $this.$v.formAdd.$anyError;
    },
    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;
      let anyError = $this.onSubmitValidationAddForm();
      if (!anyError) {
        let createCaseAction = {};
        createCaseAction["case_id"] = this.case.id;
        createCaseAction["action"] = this.formAdd.action.value;
        createCaseAction["description"] = this.formAdd.description;

        let addAttachment = {};
        if (this.formAdd.attachment != null) {
          if (this.formAdd.attachment.size > 5000000) {
            $this.$bvModal.msgBoxOk("File size is over 5MB!", {
              centered: true
            });
            return false;
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(this.formAdd.attachment);
            reader.onload = () => {
              console.log(reader.result);
              addAttachment["case_id"] = this.case.id;
              addAttachment["case_action_id"] = null;
              addAttachment["attachment"] = reader.result;
              addAttachment["attachment_filename"] =
                this.formAdd.attachment.name;
              addAttachment["attachment_type"] = this.formAdd.attachment.type;
            };
          }
        }

        let API_URL = $this.getAPIServerURL + "/api/caseservice/case-action/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, createCaseAction)
          .then(async (data) => {
            if (data.detail != undefined) {
              console.log("data_error_add", data.detail);
              this.formAddErrorMessage = data.detail;
            } else {
              let result = data.result;
              let validUrl = "";

              if (result != undefined) {
                outer_loop: for (let key15 in result) {
                  if (result[key15] != undefined) {
                    validUrl = key15;
                    break outer_loop;
                  }
                }
              }
              if (result[validUrl] != undefined) {
                let caseActionData = {};

                caseActionData = await this.fetchCaseActionByCaseActionID(
                  result[validUrl]
                );

                if (this.formAdd.attachment != null) {
                  addAttachment["case_action_id"] =
                    result[validUrl].case_action_id;

                  console.log("addAttachment", addAttachment);

                  let caseActionAttachment =
                    await this.onSubmitAddAttachmentForm(addAttachment);

                  let objectData = null;

                  objectData = await this.fetchCaseAttachmentByCaseAttachmentID(
                    caseActionAttachment
                  );

                  let arrayList = [];
                  arrayList.push(objectData);

                  caseActionData["attachment"] = arrayList;
                } else {
                  caseActionData["attachment"] = [];
                }

                this.formAdd.action = {
                  value: "INVESTIGATING",
                  name: "Investigating"
                };
                this.formAdd.description = null;
                this.formAdd.attachment = null;

                this.$v.$reset();
                this.$store.dispatch("case/addCaseAction", caseActionData);
                this.$bvModal.hide("formAddCaseAction");
                this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Action has been added successfully",
                  message_type: "success"
                });
              }
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        // $this.$bvModal.msgBoxOk("Form not completed!", {
        //   centered: true
        // });
        return false;
      }
    },
    async fetchCaseActionByCaseActionID(item) {
      let query = {
        case_action_id: item.case_action_id
      };

      let API_URL = this.getAPIServerURL + "/api/caseservice/case-action/?";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL, query);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key16 in result) {
          validUrl = key16;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }

      return [];
    },
    async onSubmitAddAttachmentForm(item) {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/caseservice/case-attachment/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.postRequest(API_URL, item);

      if (res.detail != undefined) {
        console.log("res_error_add", res.detail);
        this.formAddErrorMessage = res.detail;
      } else {
        let result = res.result;
        let validUrl = "";
        if (result !== undefined) {
          outer_loop: for (let key19 in result) {
            validUrl = key19;
            break outer_loop;
          }
        }

        if (result[validUrl] != undefined) {
          return result[validUrl];
        }
      }

      return [];
    },
    async fetchCaseAttachmentByCaseAttachmentID(item) {
      let query = {
        case_attachment_id: item.case_attachment_id
      };

      let API_URL = this.getAPIServerURL + "/api/caseservice/case-attachment/?";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL, query);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key16 in result) {
          validUrl = key16;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }

      return [];
    },

    async dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    // update action
    async updateCaseActionHandler(item) {
      this.formUpdate.case_id = this.case.id;
      this.formUpdate.case_action_id = item.id;

      for (let index6 in this.actionsList) {
        if (this.actionsList[index6].value == item.action) {
          this.formUpdate.action = {
            value: this.actionsList[index6].value,
            name: this.actionsList[index6].name
          };
        }
      }
      this.formUpdate.description = item.description;
      let file = null;

      if (item.attachment.length > 0) {
        for (let key24 in item.attachment) {
          this.formUpdate.case_attachment_id = item.attachment[key24].id;
          file = await this.dataURLtoFile(
            item.attachment[key24].attachment,
            item.attachment[key24].attachment_filename
          );
          console.log("file", file);
        }
        this.formUpdate.attachment = file;
      } else {
        this.formUpdate.case_attachment_id = null;
        this.formUpdate.attachment = null;
      }
      this.$bvModal.show("formUpdateCaseAction");
    },
    validateFormUpdate(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formUpdate[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationUpdateForm() {
      let $this = this;
      $this.$v.formUpdate.$touch();
      return $this.$v.formUpdate.$anyError;
    },
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      let $this = this;
      let anyError = $this.onSubmitValidationUpdateForm();
      if (!anyError) {
        let updateCaseAction = {};

        updateCaseAction["action"] = this.formUpdate.action.value;
        updateCaseAction["description"] = this.formUpdate.description;
        updateCaseAction["previous_data"] = {};

        let updateAttachment = {};

        if (this.formUpdate.attachment != null) {
          if (this.formUpdate.attachment.size > 5000000) {
            $this.$bvModal.msgBoxOk("File size is over 5MB!", {
              centered: true
            });
            return false;
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(this.formUpdate.attachment);
            reader.onload = () => {
              console.log(reader.result);
              updateAttachment["attachment"] = reader.result;
              updateAttachment["attachment_filename"] =
                this.formUpdate.attachment.name;
              updateAttachment["attachment_type"] =
                this.formUpdate.attachment.type;
            };
          }
        }
        console.log("this.formUpdate", this.formUpdate);
        let API_URL =
          $this.getAPIServerURL +
          "/api/caseservice/case-action/?case_action_id=" +
          this.formUpdate.case_action_id;
        const client = $this.$root.getAjaxFetchClient();
        client
          .putRequest(API_URL, updateCaseAction)
          .then(async (data) => {
            if (data.detail != undefined) {
              console.log("data_error_update", data.detail);
              this.formUpdateErrorMessage = data.detail;
            } else {
              let result = data.result;
              if (result != undefined) {
                outer_loop: for (let key27 in result) {
                  if (result[key27] == null) {
                    if (this.formUpdate.attachment != null) {
                      if (this.formUpdate.case_attachment_id == null) {
                        updateAttachment["case_action_id"] =
                          this.formUpdate.case_action_id;
                        updateAttachment["case_id"] = this.formUpdate.case_id;

                        console.log("updateAttachment", updateAttachment);

                        await this.onSubmitAddAttachmentForm(updateAttachment);
                      } else {
                        updateAttachment["case_attachment_id"] =
                          this.formUpdate.case_attachment_id;
                        await this.onSubmitUpdateAttachmentForm(
                          updateAttachment
                        );
                      }

                      let caseAttachmentAction = {};
                      caseAttachmentAction =
                        await this.fetchCaseAttachmentByCaseActionID(
                          this.formUpdate.case_action_id
                        );

                      console.log("caseAttachmentAction", caseAttachmentAction);

                      this.$store.dispatch("case/updateCaseAction", {
                        id: $this.formUpdate.case_action_id,
                        data: {
                          action: this.formUpdate.action.value,
                          description: this.formUpdate.description,
                          attachment: caseAttachmentAction
                        }
                      });

                      this.$bvModal.hide("formUpdateCaseAction");

                      this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Action has been updated successfully",
                        message_type: "success"
                      });
                    } else {
                      updateAttachment["case_attachment_id"] = null;
                      this.$store.dispatch("case/updateCaseAction", {
                        id: $this.formUpdate.case_action_id,
                        data: {
                          action: this.formUpdate.action.value,
                          description: this.formUpdate.description,
                          attachment: []
                        }
                      });

                      this.$bvModal.hide("formUpdateCaseAction");

                      this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Action has been updated successfully",
                        message_type: "success"
                      });
                    }
                  }
                  break outer_loop;
                }
              }
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        // $this.$bvModal.msgBoxOk("Form not completed!", {
        //   centered: true
        // });
        return false;
      }
    },
    async onSubmitUpdateAttachmentForm(item) {
      let $this = this;

      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case-attachment/?case_attachment_id=" +
        item.case_attachment_id;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.putRequest(API_URL, item);

      if (res.detail != undefined) {
        console.log("res_error_update", res.detail);
        this.formAddErrorMessage = res.detail;
      } else {
        let result = res.result;
        let validUrl = "";
        if (result !== undefined) {
          outer_loop: for (let key19 in result) {
            validUrl = key19;
            break outer_loop;
          }
        }

        if (result[validUrl] != undefined) {
          return result[validUrl];
        }
      }

      return [];
    },
    // delete action
    deleteCaseActionHandler(item) {
      let action = null;
      for (let key14 in this.actionsList) {
        if (item.action == this.actionsList[key14].value) {
          action = this.actionsList[key14].name;
        }
      }
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete Case Action (" +
            action +
            ") for Case " +
            this.case.case_number +
            " ?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            this.onSubmitDeleteForm(item);
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("err", err);
        });
    },
    onSubmitDeleteForm(item) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case-action/?case_action_id=" +
        item.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then((data) => {
          if (data.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key17 in result) {
                if (result != undefined) {
                  validUrl = key17;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              this.$store.dispatch("case/deleteCaseAction", item.id);
              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Case Action has been delete successfully",
                message_type: "success"
              });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    hideSelect() {
      this.isSelect = false;
    },
    openSelect() {
      this.isSelect = true;
    },
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.slice(0, limit);
      } else {
        return text;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.dropdown-toggle::after {
  /*this is responsible for the arrow you see in the button*/
  display: none;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: white;
  content: "Browse";
  background-color: #f7a155;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.text-danger {
  color: #d53e4c !important;
  font-size: small;
  font-weight: bold;
}
</style>
